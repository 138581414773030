import { Component, Input } from '@angular/core';

export enum IconColors {
  White = 'white',
  Primary = '#0071b5', // Updated Blue
  Secondary = '#0071b5', // Updated Blue
  Success = '#20c997', // Updated Green
  Danger = '#dc3545', // Updated Red
  Warning = '#ffc107', // Updated Yellow
  Info = '#0dcaf0', // Updated Light Blue
  Light = 'white',
  Dark = '#393f50', // Updated Dark Gray
}

export type IconNames  = 'exclamation-cloud' | 'map' | 'origem-destination' | 'taxi';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.css']
})
export class IconComponent {
  @Input() iconName: IconNames = 'exclamation-cloud';
  @Input() size: number = 18;
  @Input() color: IconColors = IconColors.White;
  @Input() class: string = '';
  @Input() style: string = '';

  svgContent: string = '';

  private basePath = 'assets/img/icons/';

  createPath(): string {
    return `${this.basePath}${this.iconName}.svg`;
  }

  calculateSize(): string {
    return `width: ${this.size}px;`;
  }



}