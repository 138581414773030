import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { ProfileComponent } from './modules/dashboard/profile/profile.component';
import { profileCompletionGuard } from './guards/profile-completion.guard';
import { profileCompletionNegativeGuard } from './guards/profile-completion-negative.guard';
import { NotFountComponent } from './not-fount/not-fount.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'app',
  //   pathMatch: 'full',
  // },
  {
    path: '',
    loadChildren: () =>
      import('./modules/landing-page/landing-page.module').then((m) => m.LandingPageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./modules/payment/payment.module').then((m) => m.PaymentModule),
  },
  // {
  //   path: 'booking',
  //   loadChildren: () =>
  //     import('./modules/payment/payment.module').then((m) => m.PaymentModule),
  // },
  {
    path: 'app',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [authGuard, profileCompletionGuard],
    canActivateChild: [authGuard, profileCompletionGuard],
  },
  {
    path: 'faqs',
    loadChildren: () =>
      import('./modules/faqs/faqs.module').then((m) => m.FaqsModule),
  },
  {
    path: 'privacy-policies',
    loadChildren: () =>
      import('./modules/privacy-policies/privacy-policies.module').then((m) => m.PrivacyPoliciesModule),
  },
  {
    path: 'profile-completion',
    component: ProfileComponent,
    canActivate: [authGuard, profileCompletionNegativeGuard],
  },
  {
    path: '**',
    component: NotFountComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
